import { useCallback, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MandateStatus } from '../../../constants/onboarding';
import { GENERIC_ERROR, UIModes } from '../../../entities';
import { PaymentMethodType } from '../../../entities/api/paymentMethod';
import LinkError from '../../../entities/LinkError';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { getTheme } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { GenericLoadingScreen } from '../../common/Screen';
import { getErrorPath } from '../../common/utils';
import { GenericInfoScreen } from '../../Onboarding/MessageScreen';

export default function PaymentSubmittedScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useAPIClient();
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const uiMode = params.uiMode;
  const theme = createTheme(getTheme());
  const [loading, setLoading] = useState(true);

  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(
    (params.paymentMethodType || PaymentMethodType.UNKNOWN) as PaymentMethodType,
  );
  // this is the intermediate recipient who owns the intermediate account
  // in dbs-hk world, this would be Finverse. Finverse sends money to recipient customer
  // in dbs-sg world, this could be Zetl or the customer.
  const [recipientEntityName, setRecipientEntityName] = useState(params.recipientEntityName);
  const [mandateStatus, setMandateStatus] = useState<MandateStatus>(
    (params.mandateStatus || MandateStatus.UNKNOWN) as MandateStatus,
  );

  const getPageData = useCallback(async () => {
    try {
      const shouldFetch =
        !Object.values(PaymentMethodType).includes(paymentMethodType) ||
        paymentMethodType === PaymentMethodType.UNKNOWN ||
        recipientEntityName === '' ||
        recipientEntityName === undefined;

      if (!shouldFetch) {
        // we already have the data, no need to fetch
        setLoading(false);
        return;
      }

      const paymentMethod = await client.getPaymentMethod(params.token);
      setPaymentMethodType(paymentMethod.payment_method_type);
      setRecipientEntityName(paymentMethod.recipient_entity_name);
      if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
        setMandateStatus(paymentMethod.mandate.mandate_status);
      }

      // finally set loading as false to indicate we can display the page now
      setLoading(false);
    } catch (err) {
      history.push(
        getErrorPath({
          institutionId: params.institutionId ?? 'testbank',
          error: new LinkError(GENERIC_ERROR),
          searchParamsString,
        }),
      );
    }
  }, [history, client, params, searchParamsString, paymentMethodType, recipientEntityName]);

  useEffect(() => {
    getPageData();
  });

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS, {
      ...eventProperties,
      title: t('success', { lng: 'en' }),
    });
  }, [t]);

  if (loading) {
    return <GenericLoadingScreen />;
  }

  const Icon = <CheckIcon color="primary" />;
  const Title = (
    <Typography variant="h5" fontWeight="bold">
      {t('paymentRequestSubmitted')}
    </Typography>
  );

  const PageMessage = () => {
    if (paymentMethodType === PaymentMethodType.CARD) {
      return <Typography gutterBottom>{t('paymentRequestSubmittedCardText')}</Typography>;
    }

    if (paymentMethodType === PaymentMethodType.MANDATE) {
      if (mandateStatus === MandateStatus.SUCCEEDED) {
        return (
          <Typography gutterBottom>
            {t('paymentRequestSubmittedMandateSuccessText')}: <b>{recipientEntityName}</b>.
          </Typography>
        );
      }
      return (
        <Typography gutterBottom>
          {t('paymentRequestSubmittedTextBody')}: <b>{recipientEntityName}</b>.
        </Typography>
      );
    }

    // if unknown payment method type, then no message
    return <></>;
  };
  const PageContent = (
    <>
      <PageMessage />
      <Typography gutterBottom fontWeight="bold">
        {t('doNotRetryPayment')}
      </Typography>
    </>
  );

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={uiMode as UIModes}
      title="success"
      logoUrl={customizationInfo.logoUrl}
      iconComponent={Icon}
      titleComponent={Title}
      pageContentComponent={PageContent}
    />
  );
}
