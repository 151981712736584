import { useContext, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getAmplitudeProductFlow } from '../../constants/onboarding';
import { MandateInfoContext } from '../../contexts/MandateInfoContext';
import { UIModes } from '../../entities';
import useCustomizations from '../../hooks/useCustomizations';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { decodeToken, getTheme, getProductFlow } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { commonErrorCTAButtonAction } from '../../utils/error_page';
import { useStyles } from '../common/styles';
import { MessageState, SCREEN_TYPE } from '../common/utils';
import { GetMessageScreenJSX } from '../Onboarding/MessageScreen';

export default function PaymentLinkErrorScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const theme = createTheme(getTheme(params.institution_id));
  const { state } = useLocation<MessageState>();
  const classes = useStyles(theme);
  const { mandateId } = useContext(MandateInfoContext);
  const { customizationInfo } = useCustomizations();
  const productFlow = getProductFlow(params.token);
  const token = decodeToken(params.token);
  const retryUrl = token.retryUrl ?? '';
  const { redirectToURI } = useRedirectURI();

  if (retryUrl !== '') {
    state.buttonText = t('messageScreenError');
  } else {
    state.buttonText = t('messageScreenExit');
  }

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.ERROR, {
      ...eventProperties,
      title: state.title,
      header: state.header,
      body: state.body,
      institution_id: params.institution_id,
      productFlow: getAmplitudeProductFlow(productFlow),
      uiMode: params.uiMode,
      mandateId: mandateId,
    });
  });

  return GetMessageScreenJSX({
    theme: theme,
    uiMode: params.uiMode,
    goBack: () => undefined, // noop
    state: state, //
    title: state.title,
    header: t(state.header),
    body: t(state.body),
    institutionId: params.institution_id,
    type: SCREEN_TYPE.FVERROR,
    logoUrl: customizationInfo.logoUrl,
    productFlow: productFlow,
    displayReferenceId: () => {
      if (typeof mandateId === 'string' && mandateId.length > 0) {
        return (
          <Box m={3}>
            <Typography color="secondary" align="center" className={classes.referenceIdText}>
              {t('mandateIdMessage')}
            </Typography>
            <Typography color="secondary" align="center" className={classes.referenceIdText}>
              {mandateId}
            </Typography>
          </Box>
        );
      }
    },
    onClickNextStep: () => commonErrorCTAButtonAction(params.token, params.uiMode as UIModes, redirectToURI),
  });
}
