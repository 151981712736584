import { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { UIModes } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { MandateRoutes } from '../../../routers/routes';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { getRecipientNameFromPaymentMethod } from '../../../utils/payment_method';
import Screen from '../../common/Screen';

export default function AutopayConsentScreen(): JSX.Element {
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const [userAutopaySelection, setUserAutopaySelection] = useState('true');
  const { params, searchParamsString } = useSearchParams();
  const history = useHistory();
  const client = useAPIClient();
  const token = params.token;
  const { customizationInfo } = useCustomizations();

  const getPageData = useCallback(async () => {
    try {
      const res = await Promise.all([client.getPaymentMethod(token), client.getNonSensitivePaymentUserInfo(token)]);

      const recipientName =
        res[0] !== undefined ? getRecipientNameFromPaymentMethod(res[0]) : customizationInfo.displayName ?? '';

      setRecipientName(recipientName);
      setUserAutopaySelection(String(res[1].autopay_prefill));
      setPageLoading(false);
    } catch (err) {
      history.push(getApiErrorPath(err as any, searchParamsString));
    }
  }, [client, token, history, customizationInfo.displayName, searchParamsString]);

  // on mount, we need to get payment user info from api endpoint and mark loading as done
  useEffect(() => {
    setPageLoading(true);
    getPageData();
  }, [getPageData]);

  const submitAutopayConsent = useCallback(
    async (consentVal: string) => {
      const consentValBool = consentVal.toLowerCase() === 'true';
      try {
        await client.setAutopayConsent(token, {
          autopay_consent: consentValBool,
        });
        setButtonLoading(false);
        // send them to payment submitted page
        history.push({
          pathname: MandateRoutes.PaymentSubmitted,
          search: searchParamsString,
        });
      } catch (err) {
        history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [client, history, searchParamsString, token],
  );

  const pageContent = (
    <RadioGroup value={userAutopaySelection} onChange={(e) => setUserAutopaySelection(e.target.value)}>
      <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
        {t('Autopay Selection')}:
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid container item direction="row" sx={{ alignItems: 'flex-start' }}>
          <Grid item xs={2}>
            <Radio sx={{ pt: 0 }} value="true" />
          </Grid>
          <Grid container item xs={10} spacing={2}>
            <Grid item>
              <Typography variant="h6">
                <b>{t('Yes')}</b>, {t('autopay invoices sent by')}:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                {recipientName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontStyle="italic" variant="body2">
                {t('enjoyHassleFreePayments')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="row" sx={{ alignItems: 'flex-start' }}>
          <Grid item xs={2}>
            <Radio sx={{ pt: 0 }} value="false" />
          </Grid>
          <Grid container item xs={10} spacing={2}>
            <Grid item>
              <Typography variant="h6">
                <b>{t('No')}</b>, {t('do not enroll into autopay')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontStyle="italic" variant="body2">
                {t('You will continue to receive and pay future invoices manually.')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RadioGroup>
  );

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.AUTOPAY_ENROLLMENT, {
      ...eventProperties,
    });
  }, []);

  if (pageLoading) {
    return (
      <Screen showCloseButton={false} showBackButton={false}>
        <MidContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </MidContainer>
      </Screen>
    );
  }

  return (
    <Screen
      showBackButton={false}
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={
        <LoadingButton
          variant="contained"
          loading={buttonLoading}
          onClick={() => {
            amplitude.trackButtonClick(
              PAGE_VIEWS.AUTOPAY_ENROLLMENT,
              'Select autopay consent',
              {},
              {
                autopayConsent: userAutopaySelection,
              },
            );
            setButtonLoading(true);
            submitAutopayConsent(userAutopaySelection);
          }}
          disabled={pageLoading}
        >
          {t('messageScreenContinue')}
        </LoadingButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} message="Enroll into autopay" alt="Header logo" />
      <MidContainer sx={{ gap: 1, px: 3, py: 4 }}>{pageContent}</MidContainer>
    </Screen>
  );
}
