import { EncryptionResult } from '../interfaces/crypto';

export interface LoginRequest extends EncryptionResult {
  institutionId: string;
  userId: string;
  productsRequested: string[];
  storeCredentials: boolean;
  consent: boolean;
  paymentConfig?: PaymentConfig;
}

export interface LoginResponse {
  linkStatusId?: string;
}

export interface Device {
  id: string;
  name: string;
}

export enum LinkStatusType {
  ACTION = 'ACTION',
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export enum LinkStatusMessageType {
  TITLE = 'TITLE',
  BODY = 'BODY',
  HIGHLIGHT = 'HIGHLIGHT',
  TABLE_ROW = 'TABLE_ROW',
  FOOTER = 'FOOTER',
}

export enum LinkStatusButtonType {
  SUBMIT = 'SUBMIT',
}

export interface LinkStatusMessage {
  name: string;
  type: LinkStatusMessageType;
  value: string;
}

export interface LinkStatusFieldOptionPattern {
  value: RegExp;
  message: string;
  isNumeric: boolean;
}

export interface LinkStatusFieldOption {
  label: string;
  value: string;

  // If component is a text iput
  placeholder?: string;

  // For text input
  pattern?: LinkStatusFieldOptionPattern;
}

export interface LinkStatusField {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  required?: boolean; // Currently will only be used with auth checklist and type "TEXT" or "input"
  options?: LinkStatusFieldOption[];

  // For text input
  pattern?: LinkStatusFieldOptionPattern;
  helperText?: string;
}

export interface LinkStatusButton {
  value: string;
  name: string;
  type: string;
}

export interface ActionRequiredData {
  id: string;
  type: LinkStatusType;
  name: string;
  messages: LinkStatusMessage[];
  fields: LinkStatusField[];
  buttons: LinkStatusButton[];
}

export interface CallbackUrl {
  redirect_uri: string;
}

export interface RedirectData {
  status: number;
  redirectURL: string;
}

export interface ActionableRedirectData {
  code: string;
}

export interface ActionRequest extends EncryptionResult {
  identifier: string;
}

export interface LoginScreenData {
  username: string;
  password: string;
  storeCredentials: boolean;
}

export interface ActionRequiredScreenState {
  loginScreenData: LoginScreenData;
  linkStatusData?: ActionRequiredData;
}

export interface GetAccessTokenRequest {
  audience: string;
  clientId: string;
  clientSecret: string;
  grantType: string;
}

export interface CreateLinkRequest extends EncryptionResult {
  storeCredentials: boolean;
  consent: boolean;
}

export interface RelinkRequest {
  clientId: string;
  userId: string;
  institutionId: string;
  redirectUri: string;
  scope: string;
  storeCredentials: boolean;
}

export interface ExchangeTokenRequest {
  code: string;
  clientId: string;
  grantType: string;
  redirectUri: string;
}

export interface CustomizationData {
  logo_id?: string;
  display_name?: string;
}

export type GetCustomizationResponse = CustomizationData;

export interface LoginDetails {
  captchaRequired?: string;
  info_message?: string;
}

interface StatusDetails {
  statusChanged?: Date;
}

export type PaymentInfo = {
  currencies_supported: string[] | null;
  other_info: Record<string, any>;
  payments_supported: string[];
};

export interface Institution {
  color?: string;
  countries: string[];
  institution_id: string;
  institution_name: string;
  institution_type: string;
  login_details?: LoginDetails;
  login_url?: string;
  login_methods: LoginMethod[];
  parent_institution_name?: string;
  payment_info: PaymentInfo;
  portal_name?: string;
  products_supported: string[];
  status: string;
  status_details?: StatusDetails;
  updated_at?: Date;
  user_type: string[];
  tags: string[];
  enabled?: boolean;
}

export interface LoginIdentity {
  login_identity: LILoginIdentity;
  institution: LIInstitution;
}

interface LIInstitution {
  countries: string[];
  institution_id: string;
  institution_name: string;
  portal_name: string;
}

interface LILoginIdentity {
  billing_details: any;
  created_at: string;
  customer_app_id: string;
  error: any;
  first_success: string;
  institution_id: string;
  last_success: string;
  login_identity_id: string;
  login_identity_state: string;
  login_methods_available: any;
  permissions: any;
  permissions_expiry_date: string;
  permissions_grant_date: string;
  status: string;
  updated_at: string;
  user_id: string;
  webhook: string;
}

export interface LoginMethod {
  id: string;
  name: string;
  login_fields: LoginField[];
  is_default_method: boolean;
  status: string;
}

export interface LoginField {
  key: string;
  name: string;
  type: string;
}

export enum UIModes {
  iframe = 'iframe',
  redirect = 'redirect',
  autoRedirect = 'auto_redirect',
  standalone = 'standalone',
}

export interface PaymentConfig {
  products_requested: string[];
  payment_instruction_id: string;
  user_id: string;
}

interface AuthCheckListItemOption {
  name: string;
  submitted_at: string;
  submitted_by: string;
}

export interface AuthCheckListItem {
  group_id: string;
  required: string;
  type: string;
  options: AuthCheckListItemOption[];
}

export interface LineItems {
  line_items: LineItem[];
}

export interface LineItem {
  currency: string;
  amount: string;
  item_type: string;
}

export interface ErrorResponse {
  error: {
    details: string;
    error_code: string;
    message: string;
    request_id: string;
    type: string;
  };
}
