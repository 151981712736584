import { PropsWithChildren, ReactElement } from 'react';

import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

// 16:9 ratio
const MODAL_HEIGHT = '720px';
const MODAL_WIDTH = '405px';

export const BaseContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '520px',
}));

export const TopContainer = styled(BaseContainer)(({ theme }) => ({
  textAlign: 'center',
  placeContent: 'center',
  backgroundColor: theme.palette.background.default,
  minHeight: 'min-content',
  paddingBottom: theme.spacing(2),
}));

export const MidContainer = styled(BaseContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  flex: 1,
}));

export const BottomContainer = styled(BaseContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  textAlign: 'center',
}));

export const StickyBottomContainer = styled(BaseContainer)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: 'env(safe-area-inset-bottom)',
  position: 'sticky',
  left: 0,
  bottom: 0,
}));

const baseContainerStyle: SxProps<Theme> = {
  height: `max(100%,${MODAL_HEIGHT})`, // if modal longer than page
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'background.default',
};

const standaloneContainerStyle: SxProps<Theme> = {
  backgroundColor: '#E9E9E9',
};

const baseStyle: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'common.white',
};

const inScreenModalStyle: SxProps<Theme> = {
  flex: null,
  height: MODAL_HEIGHT,
  width: MODAL_WIDTH,
  margin: 'auto',
  borderRadius: '0.5rem',
  boxShadow: 5,
  overflow: 'auto',
};

const standaloneStyle: SxProps<Theme> = {
  boxShadow: 0,
};

export const ScreenContainer = ({
  children,
  standalone = false,
  bottomStickyComponent,
}: PropsWithChildren<{
  standalone?: boolean;
  bottomStickyComponent?: JSX.Element;
}>): ReactElement => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeDesktopScreen = !isMobile && isLargeScreen;

  return (
    <Box
      sx={{
        ...baseContainerStyle,
        ...(standalone && standaloneContainerStyle),
      }}
    >
      <Box
        sx={{
          ...baseStyle,
          ...(isLargeDesktopScreen && inScreenModalStyle),
          ...(standalone && standaloneStyle),
        }}
      >
        <Box sx={{ ...baseStyle, padding: 1 }}>{children}</Box>
        {bottomStickyComponent !== undefined && (
          <StickyBottomContainer sx={{ gap: 1 }}>{bottomStickyComponent}</StickyBottomContainer>
        )}
      </Box>
    </Box>
  );
};
