import { blue, purple } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

interface InstitutionColorPalette {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  background: {
    default: string;
  };
}

const defaultColorPalette = {
  primary: {
    main: blue[500],
  },
  secondary: {
    main: '#ABABAB',
  },
  background: {
    default: '#FFFFFF',
  },
};

export function getTheme(institutionId = 'default'): ThemeOptions {
  const colors = getInstitutionColors(institutionId);
  return {
    palette: {
      primary: colors.primary,
      secondary: colors.secondary,
      background: colors.background,
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    components: {
      MuiButtonBase: {
        // The properties to apply
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
    typography: {
      fontFamily: 'clarika grotesque',
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: '31px',
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 800,
        lineHeight: '27px',
      },
      h6: {
        fontSize: '1rem',
        lineHeight: '1em',
      },
      subtitle1: {
        fontSize: 12,
      },
      body1: {
        fontSize: 16,
      },
    },
  };
}

/**
 * Get institution specific color, return default color palette if not found
 * @param institutionId
 * @returns InstitutionColorPalette
 */
function getInstitutionColors(institutionId: string): InstitutionColorPalette {
  const colors = new Map([
    [
      'hsbc',
      {
        primary: {
          main: '#DB0012',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'hsbc-business',
      {
        primary: {
          main: '#DB0012',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'bochk',
      {
        primary: {
          main: '#B31C2A',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'bochk-business',
      {
        primary: {
          main: '#B31C2A',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'stanchart',
      {
        primary: {
          main: '#00a546',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'citi',
      {
        primary: {
          main: '#5294b9',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'hangseng',
      {
        primary: {
          main: '#07A765',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'testbank',
      {
        primary: {
          main: purple[500],
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'dbs-business',
      {
        primary: {
          main: '#FF3333',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'stan-chart',
      {
        primary: {
          main: '#77b2d4',
        },
        secondary: {
          main: '#34ac52',
        },
        background: {
          default: '#0077ad',
        },
      },
    ],
    [
      'bdoph',
      {
        primary: {
          main: '#1F4F8D',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'bdoph-business',
      {
        primary: {
          main: '#1F4F8D',
        },
        secondary: {
          main: '#ABABAB',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'metrobank',
      {
        primary: {
          main: '#00539F',
        },
        secondary: {
          main: '#09bad0',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'pnb',
      {
        primary: {
          main: '#1ecdd8 ',
        },
        secondary: {
          main: '#fcfbfb',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'uob-sg',
      {
        primary: {
          main: '#2671d7',
        },
        secondary: {
          main: '#FFFFFF',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
    [
      'metrobank-web',
      {
        primary: {
          main: '#00539F',
        },
        secondary: {
          main: '#09bad0',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    ],
  ]);

  return colors.get(institutionId) || defaultColorPalette;
}
