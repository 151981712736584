import { useEffect, useMemo } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MidContainer, TopContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { ManualPaymentProvider } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import useCustomizations from '../../../hooks/useCustomizations';
import useRedirectURI from '../../../hooks/useRedirectURI';
import useSearchParams from '../../../hooks/useSearchParams';
import { getManualPaymentProvider, isManualPaymentProvider } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import Screen from '../../common/Screen';
import { BottomContainerContent } from '../BottomContainer';

export default function FpsSubmittedScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const uiMode = params.uiMode;

  const token = params.token;

  const manualPaymentProvider = useMemo((): ManualPaymentProvider => {
    const providerFromToken = getManualPaymentProvider(token);
    if (providerFromToken !== ManualPaymentProvider.UNKNOWN) {
      return providerFromToken;
    }

    const providerFromQueryParams = params.manual_payment_provider;
    return isManualPaymentProvider(providerFromQueryParams) ? providerFromQueryParams : ManualPaymentProvider.UNKNOWN;
  }, [token, params]);

  const { title, body } = useMemo(() => {
    if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
      return {
        title: 'PayNow Payment Submitted',
        body: 'Your PayNow details have been submitted. We will send you a notification when we confirm that your funds have been received',
      };
    }

    // continue using FPS as default
    return {
      title: 'FPS Payment Submitted',
      body: 'Your FPS details have been submitted. We will send you a notification when we confirm that your funds have been received',
    };
  }, [manualPaymentProvider]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS);
  }, []);

  const { setRedirectURI } = useRedirectURI();

  useEffect(() => {
    if (uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [uiMode, params.redirect_uri, setRedirectURI]);

  return (
    <Screen
      showCloseButton={uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={<BottomContainerContent />}
    >
      <LinkHeader src={customizationInfo.logoUrl} message={t('success')} alt="" />
      <TopContainer>
        <div className="centerLargeIcon">
          <CheckIcon color="primary" />
        </div>
        <Typography variant="h5" fontWeight="bold">
          {t(title)}
        </Typography>
      </TopContainer>
      <MidContainer textAlign="center" sx={{ gap: 1 }}>
        <Typography gutterBottom>{t(body)}</Typography>
        <Typography gutterBottom fontWeight="bold">
          {t('doNotRetryPayment')}
        </Typography>
      </MidContainer>
    </Screen>
  );
}
