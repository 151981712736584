import { useCallback, useEffect, useState, useMemo } from 'react';

import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { ManualPaymentProvider, OnboardingFlow } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { OnboardingRoutes, FpsRoutes, GenericErrorRoutes } from '../../../routers/routes';
import { decodeToken, getManualPaymentProvider, getOnboardingFlow } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import Screen from '../../common/Screen';

enum PaymentType {
  BANK_DIRECT_DEBIT = 'MANDATE',
  FPS = 'MANUAL',
  CARD = 'CARD',
}

const getManualPaymentText = (provider: ManualPaymentProvider): { label: string; subtext: string } => {
  if (provider === ManualPaymentProvider.SG_PAYNOW) {
    return {
      label: 'PayNow',
      subtext: 'One-time payment by PayNow QR code or transfer',
    };
  }
  // lets use HK_FPS as default
  return {
    label: 'FPS payment',
    subtext: 'One-time payment by FPS QR code or transfer.',
  };
};

interface PaymentMethod {
  type: string;
  fee: string;
}

/**
 * This function contains a hook that refreshes the payment_attempt and retrieves a new token
 * This new token should be used for all API calls since it contains the most up-to-date payment_attempt_id
 *
 * There might be a case where the `oldToken` contains a payment_attempt_id that has an unusable status, so we
 * should not use the oldToken for any API calls. Remember to always pass the `newOnboardingSearchParams`
 * on redirection since it contains the new token
 */

export default function SelectPaymentTypeScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useAPIClient();
  const { customizationInfo } = useCustomizations();
  const { params, searchParamsString } = useSearchParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [navigated, setNavigated] = useState(false); // We'll use this to determine if we navigated to a different website, e.g. Stripe
  const [paymentType, setPaymentType] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);

  const [newOnboardingToken, setNewOnboardingToken] = useState(params.token);
  const [newOnboardingSearchParams, setNewOnboardingSearchParams] = useState(searchParamsString);

  const oldToken = params.token;
  const decodedToken = decodeToken(oldToken);
  const allowedPaymentMethodsRaw = decodedToken.paymentMethodsWithFees as string;
  const allowedPaymentMethods: PaymentMethod[] = useMemo(() => {
    if (allowedPaymentMethodsRaw) {
      return JSON.parse(allowedPaymentMethodsRaw);
    }
    return [];
  }, [allowedPaymentMethodsRaw]);

  const mandatePaymentMethod: PaymentMethod | undefined = useMemo(() => {
    return allowedPaymentMethods.find((method) => method.type === 'MANDATE');
  }, [allowedPaymentMethods]);

  const cardPaymentMethod: PaymentMethod | undefined = useMemo(() => {
    return allowedPaymentMethods.find((method) => method.type === 'CARD');
  }, [allowedPaymentMethods]);

  const manualPaymentMethod: PaymentMethod | undefined = useMemo(() => {
    return allowedPaymentMethods.find((method) => method.type === 'MANUAL');
  }, [allowedPaymentMethods]);

  const manualPaymentProvider = getManualPaymentProvider(newOnboardingToken);
  const manualPaymentText = getManualPaymentText(manualPaymentProvider);

  useEffect(() => {
    setButtonDisable(true);
    if (allowedPaymentMethods.length === 0) {
      history.push('/error?error_code=PAYMENT_DISABLED&customer_app_id=' + decodedToken.customerAppId);
    }

    setPaymentType(allowedPaymentMethods[0].type);
    setButtonDisable(false);
  }, [allowedPaymentMethods, decodedToken.customerAppId, history]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SELECT_PAYMENT_TYPE, undefined, {
      manualPaymentProvider: manualPaymentProvider,
    });
  }, [manualPaymentProvider]);

  const resetPaymentAttempt = useCallback(async () => {
    try {
      const response = await client.refreshPaymentAttempt(oldToken);

      // we could have gotten error or success redirect
      const url = response.redirect_url;
      const urlObject = new URL(url);

      const onboardingFlow = getOnboardingFlow(urlObject.searchParams.get('token') ?? '');

      // if returned flow is not onboarding (i.e. UNKNOWN), then we should redirect to that URL
      if (onboardingFlow === OnboardingFlow.Unknown) {
        window.location.href = url;
      }

      // if we are here, then we successfully reset the payment attempt and should be doing onboarding flow
      // get the newest token and new search params
      setNewOnboardingToken(urlObject.searchParams.get('token') ?? '');
      setNewOnboardingSearchParams(urlObject.searchParams.toString());
      setPageLoading(false);
    } catch (err) {
      history.push(getApiErrorPath(err as any, searchParamsString));
    }
  }, [client, history, searchParamsString, oldToken]);

  useEffect(() => {
    setPageLoading(true);
    resetPaymentAttempt();
  }, [resetPaymentAttempt]);

  const bankDirectDebitAction = useCallback(() => {
    history.push({ pathname: OnboardingRoutes.SelectSenderType, search: newOnboardingSearchParams });
  }, [history, newOnboardingSearchParams]);

  const fpsAction = useCallback(async () => {
    //step 1: set button loading
    setButtonLoading(true);

    //step 2: make request to get fps token
    try {
      const response = await client.getFpsToken(newOnboardingToken);
      const fpsToken = response.fps_token.access_token;
      const newSearchParams = new URLSearchParams(newOnboardingSearchParams);
      newSearchParams.set('token', fpsToken);
      history.push({ pathname: FpsRoutes.PaymentConfirm, search: newSearchParams.toString() });
    } catch (err) {
      history.push(getApiErrorPath(err as any, newOnboardingSearchParams));
    }
  }, [history, client, newOnboardingSearchParams, newOnboardingToken]);

  const cardAction = useCallback(async () => {
    setButtonLoading(true);

    try {
      const response = await client.getRedirectUrlToCardProcessorPage(newOnboardingToken);

      if (response.card_processor_redirect_uri !== '') {
        // Set `navigated=true` in the state, so that it is saved in the bfcache
        // Then when we navigate back we can check for this and manually reset the payment attempt
        setNavigated(true);
        window.location.href = response.card_processor_redirect_uri;
      } else {
        history.push({
          pathname: GenericErrorRoutes.Base,
          search: newOnboardingSearchParams,
        });
      }
    } catch (err) {
      history.push(getApiErrorPath(err as any, newOnboardingSearchParams));
    }
  }, [history, newOnboardingSearchParams, client, newOnboardingToken]);

  if (pageLoading) {
    return (
      <Screen showCloseButton={false} showBackButton={false}>
        <MidContainer justifyContent="center" alignItems="center">
          <CircularProgress id="loading-spinner" />
        </MidContainer>
      </Screen>
    );
  }

  window.addEventListener('pageshow', async (event) => {
    if (event.persisted) {
      // This means page restored from bfcache after navigation
      // So we want to manually reset the payment attempt
      // And set buttonLoading to false
      if (navigated === true) {
        await resetPaymentAttempt();
        setNavigated(false);
        setButtonLoading(false);
      }
    }
  });

  return (
    <Screen
      showBackButton
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={
        <LoadingButton
          variant="contained"
          loading={buttonLoading}
          disabled={buttonDisable}
          onClick={() => {
            amplitude.trackPaymentTypeSelection(paymentType);
            if (paymentType === PaymentType.BANK_DIRECT_DEBIT) {
              bankDirectDebitAction();
            }
            if (paymentType === PaymentType.FPS) {
              fpsAction();
            }
            if (paymentType === PaymentType.CARD) {
              cardAction();
            }
          }}
        >
          {t('messageScreenContinue')}
        </LoadingButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} message="selectPaymentMethod" alt="Header logo" />
      <MidContainer sx={{ gap: 1, px: 3, py: 4 }}>
        <RadioGroup
          value={paymentType}
          onChange={(e) => {
            setPaymentType(e.target.value as PaymentType);
            setButtonDisable(false);
          }}
        >
          {mandatePaymentMethod !== undefined && (
            <>
              <FormControlLabel
                value={PaymentType.BANK_DIRECT_DEBIT}
                control={<Radio />}
                label={
                  <Typography variant="h6">
                    <b>{t('Direct debit')}</b> ({t('recommended')})
                  </Typography>
                }
              />
              <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Typography variant="body2">
                    {t(
                      'Best for multiple payments. Saves your bank details for future payments; you control each payment.',
                    )}
                  </Typography>
                  {mandatePaymentMethod.fee !== '' && (
                    <Typography variant="body2">
                      {t('Fee')}: {mandatePaymentMethod.fee.replace('min', t('min'))}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {manualPaymentMethod !== undefined && (
            <>
              <FormControlLabel
                value={PaymentType.FPS}
                control={<Radio />}
                label={
                  <Typography variant="h6" fontWeight="bold">
                    {t(manualPaymentText.label)}
                  </Typography>
                }
              />
              <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Typography variant="body2">{t(manualPaymentText.subtext)}</Typography>
                  {manualPaymentMethod.fee !== '' && (
                    <Typography variant="body2">
                      {t('Fee')}: {manualPaymentMethod.fee.replace('min', t('min'))}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {cardPaymentMethod !== undefined && (
            <>
              <FormControlLabel
                value={PaymentType.CARD}
                control={<Radio />}
                label={
                  <Typography variant="h6" fontWeight="bold">
                    {t('Card payment')}
                  </Typography>
                }
              />
              <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Typography variant="body2">{t('One-time payment by credit card or debit card.')}</Typography>
                  {cardPaymentMethod.fee !== '' && (
                    <Typography variant="body2">
                      {t('Fee')}: {cardPaymentMethod.fee.replace('min', t('min'))}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </RadioGroup>
      </MidContainer>
    </Screen>
  );
}
