import { useEffect } from 'react';

import { createTheme } from '@mui/material/styles';

import { UIModes } from '../../entities';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { MessageState, SCREEN_TYPE } from '../common/utils';
import { GetMessageScreenJSX } from '../Onboarding/MessageScreen';

export default function PaymentGenericSuccessScreen(): JSX.Element {
  // TODO: Add translation once language has been confirmed
  const theme = createTheme(getTheme());
  const title = 'Success';
  const header = 'Payment Completed';
  const body = 'Your payment has been received and successfully processed';
  const state: MessageState = {
    title,
    header,
    body,
  };

  const { params } = useSearchParams();
  const { setRedirectURI, redirectToURI } = useRedirectURI();

  useEffect(() => {
    if (params.uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [params.uiMode, params.redirect_uri, setRedirectURI]);

  const onClickNextStep = () => {
    amplitude.trackButtonClick('Message Screen', 'Main Action Button');

    //Behavior for redirect mode
    if (params.uiMode === UIModes.redirect) {
      redirectToURI();
    } else {
      window.parent.postMessage('success', '*');
    }
  };

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS, { title, body, header });
  });

  return GetMessageScreenJSX({
    type: SCREEN_TYPE.SUCCESS,
    theme: theme,
    uiMode: params.uiMode,
    goBack: () => undefined,
    state: state,
    title,
    header,
    body,
    institutionId: 'finverse',
    onClickNextStep: onClickNextStep,
    displayReferenceId: () => undefined,
  });
}
