import { useCallback, useEffect, useState } from 'react';

import HelpOutline from '@mui/icons-material/HelpOutline';
import { ClickAwayListener, Grid, TextField, Typography, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BulletOne, BulletTwo } from '../../../components/BulletIcons';
import { LoadingButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import Image from '../../../components/Image';
import LinkHeader from '../../../components/LinkHeader';
import { ManualPaymentProvider } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import useToggle from '../../../hooks/useToggle';
import { FpsRoutes } from '../../../routers/routes';
import { decodeToken, getManualPaymentProvider } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import Screen, { GenericLoadingScreen } from '../../common/Screen';
import { useStyles } from '../../common/styles';
import { LineItemsDisplay } from '../listLineItems';

export default function ConfirmFpsScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { customizationInfo } = useCustomizations();
  const { params, searchParamsString } = useSearchParams();
  const client = useAPIClient();
  const token = params.token;
  const decodedToken = decodeToken(token);
  const manualPaymentProvider = getManualPaymentProvider(token);
  const { pageTitle, idLabel } = (() => {
    if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
      return {
        pageTitle: 'Pay by PayNow',
        idLabel: 'PayNow ID',
      };
    }
    return {
      pageTitle: 'Pay by FPS',
      idLabel: 'FPS ID',
    };
  })();

  const [loading, setLoading] = useState(false);
  const [showErrFeedback, setShowErrFeedback] = useState(false);
  const [accountholderName, setAccountholderName] = useState('');

  const currency = decodedToken.currency;

  const format = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  const amount = format.format(decodedToken.amount);
  let fpsDetails;
  try {
    fpsDetails = JSON.parse(decodedToken.fps);
  } catch (err) {
    fpsDetails = {};
  }

  const [qrCodeBase64, setQrCodeBase64] = useState('');
  const [pageReady, setPageReady] = useState(false);
  const getQrCode = useCallback(
    async (token: string) => {
      const qrCodeResp = await client.getFpsQrCodeBase64(token);
      setQrCodeBase64(qrCodeResp.qr_code);
      setPageReady(true);
    },
    [client],
  );

  useEffect(() => {
    getQrCode(token);
  }, [getQrCode, token]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION);
  }, []);

  const submitAction = useCallback(
    async (accountholderName: string) => {
      // account holder name only required for HK_FPS
      if (manualPaymentProvider === ManualPaymentProvider.HK_FPS && accountholderName.trim() === '') {
        setShowErrFeedback(true);
        return;
      }
      // 1. set button to loading
      setLoading(true);

      // 2. submit account holder name and last 4 digits
      try {
        const data = await client.confirmManualPayment(token, accountholderName);
        amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION, 'I have paid (async response)', {
          paymentId: data.payment_id,
        });
      } catch (err) {
        history.push(getApiErrorPath(err as any, searchParamsString));

        return;
      }

      // 3. Navigate to next page
      history.push({
        pathname: FpsRoutes.PaymentSubmitted,
        search: searchParamsString,
      });
    },
    [history, searchParamsString, client, token, manualPaymentProvider],
  );

  if (!pageReady) {
    return <GenericLoadingScreen />;
  }

  return (
    <Screen
      showBackButton
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION, 'I have paid');
            submitAction(accountholderName);
          }}
        >
          {t('I have paid')}
        </LoadingButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} alt="Header logo" message={pageTitle} />
      <MidContainer sx={{ px: 2, py: 2 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {t('Pay to')}: {customizationInfo.displayName ?? decodedToken.customerName}
        </Typography>
        <LineItemsDisplay paymentType="MANUAL" sx={{ maxHeight: '20px', m: 0 }} />
        <Grid container alignItems="center" display="flex" sx={{ mt: 1 }} spacing={1}>
          <Grid item>
            <BulletOne />
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {t('Send payment to')}:
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid container item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Image sx={{ height: '150px' }} src={`data:image/jpeg;base64,${qrCodeBase64}`} alt="FPS qr code"></Image>
          </Grid>
          <Grid container item xs={6} spacing={1}>
            <Grid item>
              <Typography fontWeight="bold">{`${currency} ${amount}`}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>{t(idLabel)}</b>: {fpsDetails.id}
              </Typography>
            </Grid>
            <Grid item>
              {manualPaymentProvider === ManualPaymentProvider.HK_FPS && (
                <HkFpsRecipient legalName={fpsDetails.legal_name} />
              )}
              {manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW && (
                <SgPayNowRecipient legalName={fpsDetails.legal_name} />
              )}
            </Grid>
          </Grid>
        </Grid>
        {manualPaymentProvider === ManualPaymentProvider.HK_FPS && (
          <>
            <Grid container alignItems="center" display="flex" sx={{ mt: 1 }} spacing={1}>
              <Grid item>
                <BulletTwo />
              </Grid>
              <Grid item>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t('paymentConfirm')}:
                </Typography>
              </Grid>
            </Grid>
            <TextField
              error={showErrFeedback}
              value={accountholderName}
              onChange={(e) => setAccountholderName(e.target.value)}
              sx={{ mt: 1 }}
              variant="outlined"
              label={t('Accountholder last name or company name')}
              helperText={t('This helps us identify your payment')}
            />
          </>
        )}
      </MidContainer>
    </Screen>
  );
}

const HkFpsRecipient = ({ legalName }: { legalName: string }) => {
  const { t } = useTranslation();
  const [tooltipOpen, toggleTooltip] = useToggle(false);
  const classes = useStyles(useTheme());

  return (
    <Typography>
      <b>{t('Recipient')}</b>: {legalName} ({t('payment processor')}){' '}
      <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
        <Tooltip
          title={t('Finverse will process this payment') as string}
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => toggleTooltip()}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span style={{ verticalAlign: 'text-bottom' }}>
            <HelpOutline onClick={() => toggleTooltip()} className={classes.icon} />
          </span>
        </Tooltip>
      </ClickAwayListener>
    </Typography>
  );
};

const SgPayNowRecipient = ({ legalName }: { legalName: string }) => {
  const { t } = useTranslation();

  return (
    <Typography>
      <b>{t('Recipient')}</b>: {legalName}
    </Typography>
  );
};
