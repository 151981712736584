import * as amplitude from '@amplitude/analytics-browser';
import { parse } from 'qs';

import config from '../config';
import { getAmplitudeOnboardingFlow, getAmplitudeProductFlow } from '../constants/onboarding';
import { ActionRequiredData, LinkStatusMessage } from '../entities';
import { LinkToken } from '../entities/api/token';
import { parseToken } from '../pages/common/utils';
import { getOnboardingFlow, getProductFlow } from './token';

export const enum PAGE_VIEWS {
  CONSENT_SCREEN = 'Consent Screen',
  LOGIN_SCREEN = 'Login Screen',
  LINKING_LOADING_SCREEN = 'Linking Loading Screen',
  ACTION_REQUIRED_SCREEN = 'Action Required Screen',
  INSTITUTION_LIST = 'Institution List',
  MESSAGE_PAGE = 'Message Page',
  ERROR = 'Error',
  DDA_PAYMENT_CONFIRMATION_SCREEN = 'Direct Debit Payment Confirmation',
  AUTOPAY_ENROLLMENT = 'Autopay Consent',
  ACCOUNT_TYPE_SELECTION = 'Account Type Selection',
  MANUAL_PAYMENT_CONFIRMATION = 'Manual Payment Confirmation',
  MANUAL_PAYMENT_ACCOUNTHOLDER_DETAILS = 'Accountholder Details',
  SELECT_PAYMENT_TYPE = 'Payment Type Selection',
  SUCCESS = 'Success',
  PAYMENT_METHOD_SETUP_COMPLETE = 'Payment Method Setup Complete',
  DATA_REFRESH_LANDING_SCREEN = 'Data refresh landing screen',
}

class AmplitudeClient {
  AmplitudeEvents = {
    PAGE_VIEWED: 'PAGE_VIEWED',
    BUTTON_CLICKED: 'BUTTON_CLICKED',
    BACK_BUTTON_CLICKED: 'BACK_BUTTON_CLICKED', // special event for when navigating to previous screen
    INSTITUTION_SELECTED: 'INSTITUTION_SELECTED', // special event for when user selects institution to link
    SEARCH_BAR_TEXT: 'SEARCH_BAR_TEXT',
    LINKING_ERROR: 'LINKING_ERROR',
    LINKING_SUCCESS: 'LINKING_SUCCESS',
    API_REQUEST: 'API_REQUEST',
    API_ERROR: 'API_ERROR',
    PAYMENT_TYPE_SELECTED: 'PAYMENT_TYPE_SELECTED', //special event for when user selects a payment type
  };

  private eventProperties: Record<string, any>;

  constructor() {
    this.eventProperties = {};
  }

  clearAllEventProperties() {
    this.eventProperties = {};
  }

  private updateEventProperties(newProperties?: Record<string, any>) {
    if (newProperties !== undefined) {
      this.eventProperties = { ...this.eventProperties, ...newProperties };
    }
  }

  initialize() {
    amplitude.init(config.amplitudeApiKey);
    // opt out of analytics if runner=regression in search parameters
    amplitude.setOptOut(window.location.search.includes('runner=regression'));
  }

  cleanup() {
    this.clearAllEventProperties();
    amplitude.flush(); // flush any remaining events
    amplitude.reset();
  }

  getLandingPageProperties(): Record<string, any> {
    const searchParams = window.location.search;
    if (searchParams === '') {
      return {};
    }

    const params = parse(searchParams.substring(1)); //need to get rid of the '?'
    const linkToken = String(params['token'] ?? '');
    const productFlow = getProductFlow(linkToken);
    const onboardingFlow = getOnboardingFlow(linkToken);
    const parsedToken = parseToken<LinkToken>(linkToken);

    const properties: Record<string, any> = {
      uiMode: params['ui_mode'],
      userId: params['user_id'],
      customerAppId: parsedToken.customerAppId,
      customerAppName: parsedToken.customerAppName,
      refresh: params['refresh'],
      productFlow: getAmplitudeProductFlow(productFlow),
      onboardingFlow: getAmplitudeOnboardingFlow(onboardingFlow),
      realEnabled: params.realEnabled,
    };

    // add values that MIGHT be in the search params
    const preselectedInstitution = String(params['institution_id'] ?? '');
    if (preselectedInstitution !== '') {
      properties['institutionId'] = preselectedInstitution;
    }

    const paymentAttemptId = parsedToken.invoiceId ?? '';
    if (paymentAttemptId !== '') {
      properties['paymentAttemptId'] = paymentAttemptId;
    }

    const tppId = parsedToken.paymentLinkId ?? '';
    if (tppId !== '') {
      properties['tppId'] = tppId;
    }

    return properties;
  }

  setLoginIdentityId(loginIdentityId: string) {
    this.updateEventProperties({ loginIdentityId });
  }

  removeInstitutionId() {
    delete this.eventProperties['institutionId'];
  }

  trackPageView(pageName: PAGE_VIEWS, eventProperties?: Record<string, any>, oneTimeProperties?: Record<string, any>) {
    this.updateEventProperties(eventProperties);

    const pageViewEventProperties = {
      ...this.eventProperties,
      pageName,
      currentPath: window.location.pathname,
      ...oneTimeProperties,
    };

    amplitude.track(this.AmplitudeEvents.PAGE_VIEWED, pageViewEventProperties);
  }

  trackButtonClick(
    pageName: string,
    buttonName: string,
    eventProperties?: Record<string, any>,
    oneTimeProperties?: Record<string, any>,
  ) {
    this.updateEventProperties(eventProperties);

    const buttonClickEventProperties = {
      ...this.eventProperties,
      pageName,
      buttonName,
      currentPath: window.location.pathname,
      ...oneTimeProperties,
    };

    amplitude.track(this.AmplitudeEvents.BUTTON_CLICKED, buttonClickEventProperties);
  }

  trackBackButtonClick(oneTimeProperties?: Record<string, any>) {
    const backButtonClickEventProperties = {
      ...this.eventProperties,
      currentPath: window.location.pathname,
      ...oneTimeProperties,
    };
    amplitude.track(this.AmplitudeEvents.BACK_BUTTON_CLICKED, backButtonClickEventProperties);
  }

  trackInstitutionSelect(pageName: string, institutionId: string, eventProperties?: Record<string, any>) {
    this.updateEventProperties(eventProperties);
    this.eventProperties['institutionId'] = institutionId;

    const institutionSelectEventProperties = {
      ...this.eventProperties,
      pageName,
      currentPath: window.location.pathname,
    };

    amplitude.track(this.AmplitudeEvents.INSTITUTION_SELECTED, institutionSelectEventProperties);
  }

  trackSearchBarText(
    pageName: string,
    searchBarName: string,
    searchQuery: string,
    eventProperties?: Record<string, any>,
  ) {
    this.updateEventProperties(eventProperties);

    const searchBarTextEventProperties = {
      ...this.eventProperties,
      pageName,
      searchBarName,
      searchQuery,
      currentPath: window.location.pathname,
    };

    amplitude.track(this.AmplitudeEvents.SEARCH_BAR_TEXT, searchBarTextEventProperties);
  }

  trackLinkingError(error: string, errorMessage: string, errorDetails: string) {
    const linkingErrorEventProperties = {
      ...this.eventProperties,
      error,
      errorDetails,
      errorMessage,
    };
    amplitude.track(this.AmplitudeEvents.LINKING_ERROR, linkingErrorEventProperties);
  }

  trackLinkingSuccess() {
    const linkingSuccessEventProperties = {
      ...this.eventProperties,
      currentPath: window.location.pathname,
    };
    amplitude.track(this.AmplitudeEvents.LINKING_SUCCESS, linkingSuccessEventProperties);
  }

  trackApiError(error: string, errorMessage: string, errorDetails: string) {
    amplitude.track(this.AmplitudeEvents.API_ERROR, {
      ...this.eventProperties,
      error,
      errorDetails,
      errorMessage,
    });
  }

  trackPaymentTypeSelection(
    paymentType: string,
    eventProperties?: Record<string, any>,
    oneTimeProperties?: Record<string, any>,
  ) {
    this.updateEventProperties(eventProperties);
    this.eventProperties['paymentType'] = paymentType;
    const paymentTypeSelectEventProperties = {
      ...this.eventProperties,
      ...oneTimeProperties,
      pageName: PAGE_VIEWS.SELECT_PAYMENT_TYPE,
      currentPath: window.location.pathname,
    };

    amplitude.track(this.AmplitudeEvents.PAYMENT_TYPE_SELECTED, paymentTypeSelectEventProperties);
  }

  trackApiRequest(endpoint: string, oneTimeProperties: Record<string, string>) {
    amplitude.track(this.AmplitudeEvents.API_REQUEST, {
      endpoint: endpoint,
      ...this.eventProperties,
      ...oneTimeProperties,
    });
  }
}

const client = new AmplitudeClient();
export default client;

// Whitelist messages we know to be safe
const ALLOWED_MESSAGES = [
  'messages_device_selection_approval_title',
  'messages_id_body_bidv',
  'messages_info_screen_approval_title',
  'messages_loading_title',
  'messages_otp_body',
  'messages_otp_body_hsbc_hk_business',
  'messages_process_time_info_default',
  'messages_sent_to_phone',
  'messages_sent_to',
  'messages_thank_patience',
  'messages_trusted_device_body_vpbank2',
  'messages_wait_approval',
];

/**
 * Cleans screen data messages to make sure there is no sensitive data about mandates
 * or device selection
 *
 * Uses a whitelist approach to only allown known good message names.
 * @param data The screen data
 * @returns Screen data without sensitive stuff
 */
export const cleanActionRequiredData = (data: ActionRequiredData | undefined): ActionRequiredData | undefined => {
  if (data === undefined) {
    return undefined;
  }

  const cleanedMessages: LinkStatusMessage[] = [];

  for (const msg of data.messages) {
    // It is in allowed messages
    if (ALLOWED_MESSAGES.indexOf(msg.name) !== -1) {
      cleanedMessages.push(msg);
    }
  }

  return {
    id: 'REDACTED',
    type: data.type,
    name: data.name,
    messages: cleanedMessages,
    fields: data.fields,
    buttons: data.buttons,
  };
};
