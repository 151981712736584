import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UIModes } from '../../../entities';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { getTheme } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { GenericInfoScreen } from '../../Onboarding/MessageScreen';

export default function PaymentMethodSetupSuccessScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const uiMode = params.uiMode as UIModes;
  const theme = createTheme(getTheme());

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.PAYMENT_METHOD_SETUP_COMPLETE, {
      ...eventProperties,
    });
  }, []);

  const Icon = <CheckIcon color="primary" />;
  const Title = (
    <Typography variant="h5" fontWeight="bold">
      {t('Payment setup complete')}
    </Typography>
  );
  const PageContent = <Typography gutterBottom>{t('Your payment method has been saved.')}</Typography>;

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={uiMode}
      title="success"
      logoUrl={customizationInfo.logoUrl}
      iconComponent={Icon}
      titleComponent={Title}
      pageContentComponent={PageContent}
    />
  );
}
