/**
 * Layout of a link screen
 */

import { PropsWithChildren, ReactElement, useCallback } from 'react';

import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { MidContainer, ScreenContainer } from '../../components/Containers';
import TopBar from '../../components/Topbar';
import { UIModes } from '../../entities/api';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import amplitude from '../../services/amplitude';

export const GenericLoadingScreen = () => {
  return (
    <Screen showCloseButton={false} showBackButton={false}>
      <MidContainer justifyContent="center" alignItems="center">
        <CircularProgress />
      </MidContainer>
    </Screen>
  );
};

export type ScreenProps = PropsWithChildren<{
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  bottomStickyComponent?: JSX.Element;
}>;

export const Screen = (props: ScreenProps): ReactElement => {
  const history = useHistory();

  const {
    params: { uiMode },
  } = useSearchParams();
  const { redirectToURI } = useRedirectURI();

  const defaultOnClose = useCallback(() => {
    amplitude.cleanup();

    if (uiMode === UIModes.redirect || uiMode == UIModes.autoRedirect) {
      return redirectToURI();
    }

    return window.parent.postMessage('close', '*');
  }, [redirectToURI, uiMode]);

  const defaultOnBack = () => {
    amplitude.trackBackButtonClick();
    history.goBack();
  };

  const {
    children,
    showBackButton = true,
    showCloseButton = true,
    onBack = defaultOnBack,
    onClose = defaultOnClose,
  } = props;

  return (
    <ScreenContainer standalone={uiMode === UIModes.standalone} bottomStickyComponent={props.bottomStickyComponent}>
      <TopBar {...{ showBackButton, showCloseButton, onBack, onClose }} />
      {children}
    </ScreenContainer>
  );
};

export default Screen;
