import axios, { AxiosResponse } from 'axios';
import { ulid } from 'ulid';

import config from '../config';
import {
  ActionRequiredData,
  CreateLinkRequest,
  GetCustomizationResponse,
  Institution,
  LoginRequest,
  LoginResponse,
  ActionRequest,
  RedirectData,
  UIModes,
  LineItems,
} from '../entities';
import {
  ConfirmPaymentResponse,
  CreateFpsTokenResponse,
  CreatePaymentLinkMandateRequest,
  CreatePaymentLinkMandateResponse,
  GetFpsQrCodeResponse,
  MandateAuthResponse,
  NonSensitivePaymentUserInfo,
  PostMandateAuthRequest,
  SetAutopayConsentRequest,
} from '../entities/api/mandate';
import { RefreshPaymentAttemptResponse, GetRedirectUrlToCardProcessorResponse } from '../entities/api/payment';
import { ChangePaymentMethodResponse, PaymentMethodFvLinkResponse } from '../entities/api/paymentMethod';
import { jwksResponse } from '../interfaces/apiResponses';
import amplitude from '../services/amplitude';
import sleep from '../utils/sleep';
import { isCallbackUrl, isLinkStatusActionRequiredData } from './runtimeType';

export const contentType = {
  json: 'application/json',
  form: 'application/x-www-form-urlencoded',
};

export interface Client {
  getInstitutions(token: string): Promise<Institution[]>;
  relink(req: CreateLinkRequest, loginIdentityId: string, token: string): Promise<LoginResponse | null>;
  getMandateAuthList(token: string): Promise<MandateAuthResponse | null>;
  postMandateAuthList(token: string, req: PostMandateAuthRequest): Promise<MandateAuthResponse | null>;
  login(req: LoginRequest, token: string): Promise<LoginResponse | null>;
  action(req: ActionRequest, linkStatusIdentifier: string, token: string): Promise<LoginResponse | null>;
  getJwks(url: string): Promise<jwksResponse>;
  linkStatus(
    linkStatusIdentifier: string,
    token: string,
    uiMode?: UIModes,
  ): Promise<RedirectData | ActionRequiredData | null>;
  updateMandateInstitutionSelection(
    token: string,
    institutionId: string,
    senderType: string,
  ): Promise<{ mandate_id: string }>;
  submitSenderTypeToCreateMandate(
    token: string,
    body: CreatePaymentLinkMandateRequest,
  ): Promise<CreatePaymentLinkMandateResponse>;
  submitSenderTypeToCreateMandate(
    token: string,
    body: CreatePaymentLinkMandateRequest,
  ): Promise<CreatePaymentLinkMandateResponse>;
}

export function getApiClient(apiHost: string, oauthHost: string, paymentApiHost: string): APIClient {
  return new APIClient(apiHost, oauthHost, paymentApiHost);
}

export class APIClient implements Client {
  apiHost: string;
  oauthHost: string;
  paymentApiHost: string;

  constructor(apiHost: string, oauthHost: string, paymentApiHost: string) {
    this.apiHost = apiHost;
    this.oauthHost = oauthHost;
    this.paymentApiHost = paymentApiHost;
  }

  async getJwks(url: string): Promise<jwksResponse> {
    const resp = await this.getData(url);
    const data = <jwksResponse>resp.data;
    return data;
  }

  async getInstitutions(token: string): Promise<Institution[]> {
    const url = `${this.apiHost}/institutions/customer`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    const data = <Institution[]>resp.data;

    data.sort((a, b) => {
      // sort testbanks first (i.e. if 'testbank' is present in institution ID, then we put it first)
      if (a.institution_id.includes('testbank') && !b.institution_id.includes('testbank')) {
        // if a is testbank, but b is not, a comes first
        return -1;
      }
      if (!a.institution_id.includes('testbank') && b.institution_id.includes('testbank')) {
        // if a is not testbank, but b is, b comes first
        return 1;
      }

      // if both are testbank or neither are testbank, follow default logic

      if (a.institution_name.toLowerCase() < b.institution_name.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });

    return data;
  }

  async getInstitution(token: string, institutionId: string): Promise<Institution> {
    const url = `${this.apiHost}/institutions/${institutionId}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    const data = <Institution>resp.data;

    return data;
  }

  // POST /link/relink
  async relink(req: CreateLinkRequest, loginIdentity: string, token: string): Promise<LoginResponse | null> {
    const url = `${this.apiHost}/link/relink/${loginIdentity}`;
    const requestBody = {
      consent: req.consent,
      store_credential: req.storeCredentials,
      encrypted_credentials: {
        keyId: req.keyId,
        envelopeEncryptionKey: req.envelopeEncryptionKey,
        ciphertext: req.envelope.ciphertext,
        initializationVector: req.envelope.initializationVector,
        messageAuthenticationCode: req.envelope.messageAuthenticationCode,
      },
    };
    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      requestBody,
    );
    return { linkStatusId: resp.data.login_identity.login_identity_id };
  }

  // GET /mandates/auth
  async getMandateAuthList(token: string): Promise<MandateAuthResponse | null> {
    const url = `${this.apiHost}/mandates/auth`;
    const resp = await this.getData(url, { Authorization: `Bearer ${token}` });
    return resp.data;
  }

  // POST /mandates/auth
  async postMandateAuthList(token: string, req: PostMandateAuthRequest): Promise<MandateAuthResponse | null> {
    const body = {
      ciphertext: req.envelope.ciphertext,
      initialization_vector: req.envelope.initializationVector,
      message_authentication_code: req.envelope.messageAuthenticationCode,
      envelope_encryption_key: req.envelopeEncryptionKey,
      key_id: req.keyId,
    };
    const url = `${this.apiHost}/mandates/auth`;
    const resp = await this.postData(url, { 'Content-Type': contentType.json, Authorization: `Bearer ${token}` }, body);
    return resp.data;
  }

  // POST /payment-link/confirm
  async confirmPayment(token: string): Promise<ConfirmPaymentResponse> {
    const url = `${this.apiHost}/payment_links/confirm`;
    const resp = await this.postData(url, { Authorization: `Bearer ${token}` });
    return resp.data;
  }

  async submitSenderTypeToCreateMandate(
    token: string,
    body: CreatePaymentLinkMandateRequest,
  ): Promise<CreatePaymentLinkMandateResponse> {
    const url = `${this.apiHost}/payment_links/mandates`;
    const resp = await this.postData(url, { Authorization: `Bearer ${token}` }, body);
    return resp.data;
  }

  async getNonSensitivePaymentUserInfo(token: string): Promise<NonSensitivePaymentUserInfo> {
    const url = `${this.apiHost}/payment_link/fvlink/payment_user/sender`;
    const resp = await this.getData(url, { Authorization: `Bearer ${token}` });
    return resp.data;
  }

  async setAutopayConsent(token: string, body: SetAutopayConsentRequest): Promise<void> {
    const url = `${this.apiHost}/payment_link/fvlink/payment_user/autopay`;
    await this.postData(url, { Authorization: `Bearer ${token}` }, body);
    return;
  }

  async getFpsToken(token: string): Promise<CreateFpsTokenResponse> {
    const url = `${this.apiHost}/payment_links/fps/token`;
    const response = await this.postData(url, { Authorization: `Bearer ${token}` });
    return response.data;
  }

  async getFpsQrCodeBase64(token: string): Promise<GetFpsQrCodeResponse> {
    const url = `${this.apiHost}/payment_links/fps/qr_code`;
    const response = await this.getData(url, { Authorization: `Bearer ${token}` });
    return response.data;
  }

  async getLineItemsForDisplay(token: string, paymentType: string): Promise<LineItems> {
    const url = `${this.apiHost}/calculate/line_items/${paymentType}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    const data = <LineItems>resp.data;

    return data;
  }

  // this API calls returns payment_id but right now we only care about 200
  async confirmManualPayment(token: string, accountholderName: string): Promise<{ payment_id: string }> {
    const body = {
      accountholder_name: accountholderName,
    };
    const url = `${this.apiHost}/payments/manual_payment`;
    const res = await this.postData(url, { Authorization: `Bearer ${token}` }, body);
    return res.data;
  }

  // Still keeping senderType as one of the params as we may add senderType in the future
  async updateMandateInstitutionSelection(
    token: string,
    institutionId: string,
    senderType: string,
  ): Promise<{ mandate_id: string }> {
    const url = `${this.apiHost}/mandates/institution_selection`;
    // Not using senderType currently but we may pass it when we make senderType option when creating mandate
    const body = { institution_id: institutionId };
    const resp = await this.postData(url, { 'Content-Type': contentType.json, Authorization: `Bearer ${token}` }, body);
    return resp.data;
  }

  // (WOAUTH) POST /login
  async login(req: LoginRequest, token: string): Promise<LoginResponse | null> {
    // woauth use the state as access_token
    const url = `${this.apiHost}/link`;
    let requestBody = {
      institution_id: req.institutionId,
      user_id: req.userId,
      consent: req.consent,
      encrypted_credentials: {
        keyId: req.keyId,
        envelopeEncryptionKey: req.envelopeEncryptionKey,
        ciphertext: req.envelope.ciphertext,
        initializationVector: req.envelope.initializationVector,
        messageAuthenticationCode: req.envelope.messageAuthenticationCode,
      },
      products_requested: req.productsRequested,
      store_credentials: req.storeCredentials,
    };

    if (req.paymentConfig) {
      requestBody = { ...requestBody, ...req.paymentConfig };
    }

    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      requestBody,
    );
    return { linkStatusId: resp.data.login_identity.login_identity_id };
  }

  // (FV API) GET /customer/customizations
  async getCustomization(token: string): Promise<GetCustomizationResponse> {
    const url = `${this.apiHost}/customer/customizations`;
    const resp = await this.getData(url, {
      'Content-Type': contentType.json,
      Authorization: `Bearer ${token}`,
    });

    return {
      logo_id: resp.data.logo_id,
      display_name: resp.data.display_name,
    };
  }

  async action(req: ActionRequest, linkStatusIdentifier: string, token: string): Promise<LoginResponse | null> {
    const url = `${this.apiHost}/link/action/${linkStatusIdentifier}`;
    const requestBody = {
      action_id: req.identifier,
      encrypted_credentials: {
        keyId: req.keyId,
        envelopeEncryptionKey: req.envelopeEncryptionKey,
        ciphertext: req.envelope.ciphertext,
        initializationVector: req.envelope.initializationVector,
        messageAuthenticationCode: req.envelope.messageAuthenticationCode,
      },
    };
    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      requestBody,
    );
    return { linkStatusId: resp.data.login_identity.login_identity_id };
  }

  // This is for checking the link status
  async linkStatus(
    linkStatusIdentifier: string,
    linkToken: string,
    uiMode: UIModes = UIModes.iframe,
  ): Promise<RedirectData | ActionRequiredData | null> {
    const url = `${this.apiHost}/link/fvlink/status/${linkStatusIdentifier}`;

    const resp = await this.getData(url, { Authorization: `Bearer ${linkToken}` });

    // if the response was redirected, return with the redirect url
    const data = resp.data;
    if (isCallbackUrl(data)) {
      if (uiMode === UIModes.iframe || uiMode === UIModes.standalone) {
        // call this redirect_uri if only in iframe mode
        await this.getData(data.redirect_uri, {});
      }
      return { status: resp.status, redirectURL: data.redirect_uri };
    }

    const actionData = { ...data.action, id: data.action.action_id };
    // remove actionId field so the schema matches with no additional fields
    delete actionData.action_id;
    // There was no redirect could be user action case or some error
    if (isLinkStatusActionRequiredData(actionData)) {
      return actionData;
    }

    // this should not happen but we are returning null so we will display a generic error screen
    return null;
  }

  async refreshPaymentAttempt(token: string): Promise<RefreshPaymentAttemptResponse> {
    const url = `${this.apiHost}/payment_link/fvlink/payment_attempt/refresh`;
    const response = await this.postData(url, { Authorization: `Bearer ${token}` });

    return response.data;
  }

  async getRedirectUrlToCardProcessorPage(token: string): Promise<GetRedirectUrlToCardProcessorResponse> {
    const url = `${this.apiHost}/payment_links/card`;
    const response = await this.postData(url, { Authorization: `Bearer ${token}` });
    return response.data;
  }

  async getPaymentMethod(token: string): Promise<PaymentMethodFvLinkResponse> {
    const url = `${this.apiHost}/payment_link/fvlink/payment_method`;
    const response = await this.getData(url, { Authorization: `Bearer ${token}` });
    return response.data;
  }

  async changePaymentMethod(token: string): Promise<ChangePaymentMethodResponse> {
    const url = `${this.apiHost}/payment_link/fvlink/payment_method/change`;
    const response = await this.postData(url, { Authorization: `Bearer ${token}` });
    return response.data;
  }

  // Helper functions to either get or post data. These are private functions so coverage should be ignored.
  /* istanbul ignore next */
  async postData(url: string, headers = {}, data = {}): Promise<AxiosResponse> {
    return this.makeApiRequest(url, headers, 'POST', data);
  }
  /* istanbul ignore next */
  async getData(url: string, headers = {}): Promise<AxiosResponse> {
    return this.makeApiRequest(url, headers, 'GET');
  }
  /* istanbul ignore next */
  async makeApiRequest(
    url: string,
    headers: Record<string, string>,
    method: 'GET' | 'POST',
    data?: Record<string, any>,
  ) {
    const parsedUrl = new URL(url);
    const requestId = this.generateRequestId();

    try {
      const response = await networkRequestRetryWrapper(() => {
        return axios.request({
          url: url,
          headers: {
            ...headers,
            'X-Request-Id': requestId,
          },
          method: method,
          data: data,
        });
      });

      amplitude.trackApiRequest(parsedUrl.pathname, {
        requestId: requestId,
        status: response.status.toString(),
        method: method,
      });
      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response !== undefined) {
        amplitude.trackApiRequest(parsedUrl.pathname, {
          requestId: requestId,
          status: e.response.status.toString(),
          method: method,
          ...getErrorProperties(e.response),
        });
      } else {
        amplitude.trackApiRequest(parsedUrl.pathname, {
          requestId: requestId,
          status: 'NO_RESPONSE',
          err: JSON.stringify(e),
        });
      }

      throw e;
    }
  }

  // just a wrapper that can change so keeping this abstraction
  generateRequestId(): string {
    return ulid();
  }
}

/**
 * Returns a response with some error details to be fed into amplitude, if it exists
 */
const getErrorProperties = (response: AxiosResponse): Record<string, string> => {
  if (response.data !== undefined && response.data.error !== undefined) {
    return {
      error_code: response.data.error.error_code,
      error_message: response.data.error.message,
      error_details: response.data.error.details,
    };
  }

  return {};
};

// if env is dev2, we will retry requests that fail with 500 status code
const networkRequestRetryWrapper = async (axiosCall: () => Promise<AxiosResponse>) => {
  const NUM_RETRIES = 3;
  const sleepDuration = 1000;

  for (let i = 1; i <= NUM_RETRIES; i++) {
    try {
      const response = await axiosCall();
      return response;
    } catch (err) {
      if (config.env !== 'dev2' && config.env !== 'local') {
        // we only want retries in dev2 and local
        throw err;
      }

      if (!axios.isAxiosError(err)) {
        // not an axios error, throw it as is
        throw err;
      }

      // we got an axios error
      if (err.response === undefined) {
        // no response
        throw err;
      }

      if (err.response.status >= 500 && i < NUM_RETRIES) {
        // got 500 error, good to retry after a short pause
        await sleep(sleepDuration);
        continue;
      }

      // non-500 error
      throw err;
    }
  }

  throw new Error('should never reach this branch');
};
